import { Checkbox, CheckboxProps } from 'antd';
import './index.scss';
import React from 'react';

interface CustomCheckboxProps extends CheckboxProps {
  clasName?: string;
  child?: React.ReactElement | string;
}

const CustomCheckbox = (props: CustomCheckboxProps) => {
  const { clasName, child, ...restProps } = props;

  return (
    <Checkbox className={`ant-custom-checkbox ${clasName ?? ''}`} {...restProps}>
      {child}
    </Checkbox>
  );
};

export default CustomCheckbox;
