import {
  CloseOutlined,
  LoadingOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Avatar, Divider, Image, Spin } from 'antd';
import dayjs from 'dayjs';
import { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { itemInternalMessageApi } from '../../../../../apis';
import { ItemInternalMessages } from '../../../../../apis/client-axios';
import { RootState } from '../../../../../store';
import { AdminRole, TIME_FORMAT } from '../../../../../util/constants';
import { Helper } from '../../../../../util/helper';

export interface InternalMessageProps {
  itemMessage: ItemInternalMessages;
}
const InternalMessage = (props: InternalMessageProps) => {
  const { itemMessage } = props;

  const { authUser } = useSelector((state: RootState) => state.auth);
  const intl = useIntl();

  const [item, setItem] = useState(itemMessage);
  const [visibleSender, setVisibleSender] = useState(false);
  const [previewSender, setPreviewSender] = useState<{ source: string; index: number } | undefined>(undefined);
  const [visibleReceiver, setVisibleReceiver] = useState(false);
  const [previewReceiver, setPreviewReceiver] = useState<{ source: string; index: number } | undefined>(undefined);
  const [isShowMessageTranslate, setIsShowMessageTranslate] = useState(false);

  const { mutate: translateUserMessage, isPending: isTranslating } = useMutation({
    mutationFn: () =>
      itemInternalMessageApi.itemIntenalControllerTranslateInternalMessage({
        internalMessageId: item.id,
      }),
    onSuccess: (res) => {
      if (res.data?.length > 0) {
        setItem({
          ...itemMessage,
          messageTranslated: res.data,
        });
        setIsShowMessageTranslate(true);
      }
    },
    onError: (error) => {
      setIsShowMessageTranslate(false);
    },
  });

  const handleTranslateMessage = () => {
    if (!item?.messageTranslated) {
      translateUserMessage();
    } else {
      setIsShowMessageTranslate(!isShowMessageTranslate);
    }
  };

  const isShowTranslateButton = useMemo(() => {
    if (
      [AdminRole.ADMIN, AdminRole.STAFF].indexOf(authUser?.role as AdminRole) >= 0 &&
      item?.sendStaff?.role == AdminRole.OPERATION
    ) {
      return true;
    } else if (authUser?.role == AdminRole.OPERATION && item?.sendStaff?.role != AdminRole.OPERATION) {
      return true;
    } else {
      return false;
    }
  }, [item, authUser]);

  return (
    <Fragment key={item?.id}>
      {!item?.isClosedInquiryMessage ? (
        +item?.sendStaffId !== authUser?.id ? (
          <div key={item?.id} className="item-detail_message_content_other">
            <div className="item-detail_message_content_other_avatar">
              {/* <img src={Helper.getSourceFile(authUser?.avatar?.source)} alt="alt.." /> */}
              <Avatar size={32} src={Helper.getSourceFile(item?.sendStaff?.avatar?.source)}></Avatar>
            </div>
            <div className="item-detail_message_content_other_text w-100">
              {!!item?.itemMessageImages?.length && (
                <div>
                  {/* <Avatar
                          size={32}
                          src={Helper?.getSourceFile(
                            item.sendUser?.avatar?.source || item.sendStaff?.avatar?.source,
                          )}
                        /> */}
                  <div className="d-flex flex-column">
                    <span className="item-detail_message-file">
                      <Image.PreviewGroup
                        preview={
                          visibleReceiver
                            ? {
                                rootClassName: `image-preview__container ${!!item?.itemMessageImages?.length && item?.itemMessageImages?.length < 2 ? 'image-preview__container-limit' : ''} `,
                                onChange: (current, prev) => {
                                  setPreviewReceiver({
                                    source: item?.itemMessageImages[current]?.source,
                                    index: current,
                                  });
                                },
                                src: Helper.getSourceFile(previewReceiver?.source),
                                toolbarRender: (
                                  _,
                                  {
                                    transform: { scale },
                                    actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                    current,
                                  },
                                ) => (
                                  <div className="ant-image-preview-operations">
                                    <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                    </div>
                                    <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                      <RotateRightOutlined onClick={onRotateRight} />
                                    </div>
                                    <div
                                      className={`ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut ${scale === 1 && 'ant-image-preview-operations-operation-disabled'}`}
                                    >
                                      <ZoomOutOutlined disabled={true} onClick={onZoomOut} />
                                    </div>
                                    <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                    </div>
                                    <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                      <Divider type="vertical" className="bg-white" />
                                    </div>
                                    <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                      <CloseOutlined
                                        disabled={scale === 50}
                                        onClick={() => setVisibleReceiver(false)}
                                      />
                                    </div>
                                  </div>
                                ),
                              }
                            : false
                        }
                      >
                        {item.itemMessageImages?.map((item, index) => {
                          return (
                            <Image
                              key={item?.id + index}
                              onClick={() => {
                                setVisibleReceiver(true);
                                setPreviewReceiver({
                                  source: item?.source,
                                  index: +index + 1,
                                });
                              }}
                              width={98}
                              height={98}
                              className="rounded"
                              src={Helper.getSourceFile(item?.source)}
                            />
                          );
                        })}
                      </Image.PreviewGroup>
                    </span>
                    {/* <div className="d-flex flex-column">
                            <span className="color-sub-title font-weight-600 font-size-12 m-t-2">
                              {item.sendStaff?.name || item.sendStaff?.loginId || item.sendStaff?.loginId}
                            </span>
                            <span className="color-sub-cancel font-size-10 m-t-2">
                              {dayjs(item.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                            </span>
                          </div> */}
                  </div>
                </div>
              )}
              <p className="item-detail_message_content_other_text_content m-t-2">
                {isShowMessageTranslate ? item?.messageTranslated : item?.message}
              </p>
              <p
                className={`item-detail_message_content_other_text_translate ${isShowTranslateButton ? '' : 'display-none'}`}
              >
                {isTranslating ? (
                  <div className="item-detail_message_content_other_text_translate_spin">
                    <Spin
                      style={{ position: 'unset', display: 'flex', height: 16, width: 16, marginTop: 5 }}
                      spinning={isTranslating}
                      indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="item-detail_message_content_other_text_translate_button"
                    onClick={handleTranslateMessage}
                  >
                    {isShowMessageTranslate ? (
                      <>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.545 6.714L4.11 8H3L4.862 3H6.146L8 8H6.833L6.398 6.714H4.545ZM6.179 5.978L5.5 3.956H5.451L4.772 5.978H6.179Z"
                            fill="#1C86ED"
                          />
                          <path
                            d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L9 0C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V5H14C14.5304 5 15.0391 5.21071 15.4142 5.58579C15.7893 5.96086 16 6.46957 16 7V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H7C6.46957 16 5.96086 15.7893 5.58579 15.4142C5.21071 15.0391 5 14.5304 5 14V11H2C1.46957 11 0.960859 10.7893 0.585786 10.4142C0.210714 10.0391 0 9.53043 0 9V2ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V9C1 9.26522 1.10536 9.51957 1.29289 9.70711C1.48043 9.89464 1.73478 10 2 10H9C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9V2C10 1.73478 9.89464 1.48043 9.70711 1.29289C9.51957 1.10536 9.26522 1 9 1H2ZM9.138 10.995C9.33067 11.2957 9.54067 11.5777 9.768 11.841C9.02 12.416 8.095 12.842 7 13.133C7.178 13.35 7.451 13.768 7.555 14C8.68 13.641 9.635 13.156 10.441 12.506C11.218 13.171 12.18 13.671 13.371 13.978C13.504 13.724 13.785 13.305 14 13.088C12.875 12.835 11.943 12.394 11.18 11.804C11.861 11.057 12.402 10.153 12.801 9.047H14V8H11V9.047H11.765C11.447 9.891 11.025 10.593 10.493 11.177C10.346 11.0205 10.2074 10.8563 10.078 10.685C9.79559 10.8655 9.47236 10.9721 9.138 10.995Z"
                            fill="#1C86ED"
                          />
                        </svg>
                        <span>{authUser?.role == AdminRole.OPERATION ? 'Show original' : '原文を表示'}</span>
                      </>
                    ) : (
                      <>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.545 6.714L4.11 8H3L4.862 3H6.146L8 8H6.833L6.398 6.714H4.545ZM6.179 5.978L5.5 3.956H5.451L4.772 5.978H6.179Z"
                            fill="#514B4D"
                          />
                          <path
                            d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L9 0C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V5H14C14.5304 5 15.0391 5.21071 15.4142 5.58579C15.7893 5.96086 16 6.46957 16 7V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H7C6.46957 16 5.96086 15.7893 5.58579 15.4142C5.21071 15.0391 5 14.5304 5 14V11H2C1.46957 11 0.960859 10.7893 0.585786 10.4142C0.210714 10.0391 0 9.53043 0 9V2ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V9C1 9.26522 1.10536 9.51957 1.29289 9.70711C1.48043 9.89464 1.73478 10 2 10H9C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9V2C10 1.73478 9.89464 1.48043 9.70711 1.29289C9.51957 1.10536 9.26522 1 9 1H2ZM9.138 10.995C9.33067 11.2957 9.54067 11.5777 9.768 11.841C9.02 12.416 8.095 12.842 7 13.133C7.178 13.35 7.451 13.768 7.555 14C8.68 13.641 9.635 13.156 10.441 12.506C11.218 13.171 12.18 13.671 13.371 13.978C13.504 13.724 13.785 13.305 14 13.088C12.875 12.835 11.943 12.394 11.18 11.804C11.861 11.057 12.402 10.153 12.801 9.047H14V8H11V9.047H11.765C11.447 9.891 11.025 10.593 10.493 11.177C10.346 11.0205 10.2074 10.8563 10.078 10.685C9.79559 10.8655 9.47236 10.9721 9.138 10.995Z"
                            fill="#514B4D"
                          />
                        </svg>
                        <span>{authUser?.role == AdminRole.OPERATION ? 'Translate' : '日本語に翻訳する'}</span>
                      </>
                    )}
                  </button>
                )}
              </p>
              <p className="item-detail_message_content_other_text_name">
                {item?.sendStaff?.name || item?.sendStaff?.loginId}
              </p>
              <p className="item-detail_message_content_other_text_time">
                {' '}
                {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
              </p>
            </div>
          </div>
        ) : (
          <>
            {item?.sendStaffId === authUser?.id ? (
              <div className="item-detail_message_content_me">
                <div className="item-detail_message_content_me_avatar">
                  {/* <img src={Helper.getSourceFile(authUser?.avatar?.source)} alt="alt.." /> */}
                  <Avatar size={32} src={Helper.getSourceFile(authUser?.avatar?.source)}></Avatar>
                </div>
                <div className="item-detail_message_content_me_text w-100">
                  {!!item?.itemMessageImages?.length && (
                    <div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="item-detail_message-file">
                          <Image.PreviewGroup
                            preview={
                              visibleSender
                                ? {
                                    rootClassName: `image-preview__container ${!!item?.itemMessageImages?.length && item?.itemMessageImages?.length < 2 ? 'image-preview__container-limit' : ''} `,
                                    onChange: (current, prev) => {
                                      setPreviewSender({
                                        source: item?.itemMessageImages[current]?.source,
                                        index: current,
                                      });
                                    },
                                    src: Helper.getSourceFile(previewSender?.source),
                                    toolbarRender: (
                                      _,
                                      {
                                        transform: { scale },
                                        actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                      },
                                    ) => (
                                      <div className="ant-image-preview-operations">
                                        <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                          <RotateLeftOutlined onClick={onRotateLeft} />
                                        </div>
                                        <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                          <RotateRightOutlined onClick={onRotateRight} />
                                        </div>
                                        <div
                                          className={`ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut ${scale === 1 && 'ant-image-preview-operations-operation-disabled'}`}
                                        >
                                          <ZoomOutOutlined disabled={true} onClick={onZoomOut} />
                                        </div>
                                        <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                        </div>
                                        <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                          <Divider type="vertical" className="bg-white" />
                                        </div>
                                        <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut-close">
                                          <CloseOutlined
                                            disabled={scale === 50}
                                            onClick={() => {
                                              setVisibleSender(false);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ),
                                  }
                                : false
                            }
                          >
                            {item?.itemMessageImages?.map((item, index) => {
                              return (
                                <Image
                                  key={item?.id + index}
                                  onClick={() => {
                                    setVisibleSender(true);
                                    setPreviewSender({
                                      source: item?.source,
                                      index: +index + 1,
                                    });
                                  }}
                                  width={98}
                                  height={98}
                                  className="rounded"
                                  src={Helper.getSourceFile(item?.source)}
                                />
                              );
                            })}
                          </Image.PreviewGroup>
                        </span>
                        {/* <span className="color-sub-title font-weight-600 font-size-12 m-t-2">
                                {item?.sendStaff?.name || item?.sendStaff?.loginId}
                              </span>
                              <span className="color-sub-cancel font-size-10 m-t-2">
                                {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                              </span> */}
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-end">
                    <p className="item-detail_message_content_me_text_content m-t-2">{item?.message}</p>
                  </div>
                  <p className="item-detail_message_content_me_text_name">
                    {item?.sendStaff?.name || item?.sendStaff?.loginId}
                  </p>
                  <p className="item-detail_message_content_me_text_time">
                    {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="item-detail_message_content_me">
                <div className="item-detail_message_content_me_avatar">
                  {/* <img src="/assets/icons/chatsupport.png" alt="" /> */}
                  <Avatar size={32} src={'/assets/icons/chatsupport.png'}></Avatar>
                </div>
                <div className="item-detail_message_content_me_text w-100">
                  <div className="d-flex justify-content-end">
                    <p className="item-detail_message_content_me_text_content-other m-t-2">{item?.message}</p>
                  </div>
                  <p className="item-detail_message_content_me_text_name">
                    {item?.sendStaff?.name || item?.sendStaff?.loginId}
                  </p>
                  <p className="item-detail_message_content_me_text_time">
                    {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                  </p>
                </div>
              </div>
            )}
          </>
        )
      ) : (
        <div className="item-detail_message_content_seen">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M4.125 13.125L9.375 18.375L19.875 7.125"
                stroke="#514B4D"
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>{intl.formatMessage({ id: 'chat-item.internal.closed' })}</span>
          </p>
        </div>
      )}
    </Fragment>
  );
};
export default InternalMessage;
