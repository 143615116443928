import { useMutation, useQuery } from '@tanstack/react-query';
import { Avatar, Button, Form, Input, Modal, Pagination } from 'antd';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { storageItemApi, storageProcessApi } from '../../../apis';
import { RequestManagementResponse, UpdatePreparingDoneDto } from '../../../apis/client-axios';
import { SvgAvatarDefault } from '../../../components/@svg/SvgAvatarDefault';
import SvgBlueCircle from '../../../components/@svg/SvgBlueCircle';
import SvgBoxDrop from '../../../components/@svg/SvgBoxDrop';
import SvgCoppy from '../../../components/@svg/SvgCheck';
import SvgCloseModalIcon from '../../../components/@svg/SvgCloseModalIcon';
import { SvgPrintIcon } from '../../../components/@svg/SvgPrintIcon';
import SvgIncrease from '../../../components/@svg/SvgScanIcon';
import { CustomCommonButton } from '../../../components/buttons/common/CustomButton';
import DatepickerCustom from '../../../components/DatepickerCustom';
import FormWrap from '../../../components/FormWrap';
import CustomSearchInput from '../../../components/input/CustomSearchInput';
import NotificationError from '../../../components/notifications/NotificationError';
import NotificationSuccess from '../../../components/notifications/NotificationSuccess';
import { RootState } from '../../../store';
import { QUERY_KEY, STATUS_ITEM_MANAGEMENT, statusChat, TIME_FORMAT } from '../../../util/constants';
import {
  formatDate,
  handleShowInternalDiscussStatus,
  Helper,
  renderStatus,
  renderStatusImage,
  TokyoTimezone,
} from '../../../util/helper';
import './index.scss';
import { decode as decodeHtml } from 'he';
import { debounce } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SvgMatch } from '../../../components/@svg/SvgMatch';
import { SvgNotMatch } from '../../../components/@svg/SvgNotMatch';
import CheckboxCustom from '../../../components/buttonCustom/CheckboxCustom';
import ButtonDetail from '../../../components/buttons/ButtonDetail';
import TableWrap from '../../../components/TableWrap';
import { ADMIN_ROUTE_PATH } from '../../../constants/router';
import CustomCheckbox from '../../../components/checkbox';

interface IItemDetail {
  sku: string;
  internalSku: string;
  productName: string;
  shipToBuyerItemId: number;
  labelUrl: string | undefined;
  battery: string;
}

interface ICheckSku {
  empty: boolean;
  isMatch: boolean;
}

interface ITabAction {
  activeTab: number;
  fullTextSearch: string | undefined;
  checkboxValue: boolean;
  formattedStartDate: string | undefined;
  formattedEndDate: string | undefined;
  tab:
    | 'STOCK'
    | 'SHIP'
    | 'PHOTO_INIT'
    | 'PHOTO_REQUEST'
    | 'DISPOSAL'
    | 'QUESTION'
    | 'CHAT'
    | 'ALL'
    | 'WAITING_STOCK'
    | 'IN_STOCK'
    | 'ITEM_SHIP'
    | 'ITEM_DISPOSAL'
    | 'COMPLETE';
}

const n = (key: keyof UpdatePreparingDoneDto) => key;

const RequestManagement: React.FC = () => {
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [form] = Form.useForm<FormInstance>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [lstData, setLstData] = useState<RequestManagementResponse[]>([]);
  const [internalSkuInput, setInternalSkuInput] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [enableApi, setEnableApi] = useState<boolean>(false);
  const [isCheckSku, setIsCheckSku] = useState<ICheckSku>({
    empty: true,
    isMatch: false,
  });
  const [checkPin, setCheckPin] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef<any>(null);
  const [detailItem, setDetailItem] = useState<IItemDetail>({
    sku: '',
    internalSku: '',
    productName: '',
    shipToBuyerItemId: 0,
    labelUrl: '',
    battery: '',
  });
  const intl = useIntl();
  const role = authUser?.role || '';
  const [searchParams, setSearchParams] = useSearchParams();
  const [fakeSearchValue, setFakeSearchValue] = useState<string>('');
  const refDebounceSearch = useRef<NodeJS.Timeout>(null);
  const {
    data: countRequest,
    refetch: refechCount,
    isFetching: isFetchingCount,
  } = useQuery({
    queryKey: [QUERY_KEY.COUNT_TAB_REQUEST, searchParams.get('tab')],
    staleTime: 1000,
    queryFn: () => storageItemApi.storageItemControllerCountForRequestList(),
  });
  const tabTitles = useMemo(
    () => [
      {
        index: 0,
        name: intl.formatMessage({ id: 'request.inspection' }),
        text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
        quantity: countRequest?.data?.stock,
        role: ['operation'],
        tab: 'STOCK',
      },
      {
        index: 1,
        name: intl.formatMessage({ id: 'request.warehousing_processing' }),
        text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
        quantity: countRequest?.data?.stock,
        role: ['administrator'],
        tab: 'STOCK',
      },
      {
        index: 2,
        name: intl.formatMessage({ id: 'request.TAKE_BY_OPERATION' }),
        text_scheduling: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
        quantity: countRequest?.data?.photoInit,
        role: ['administrator', 'operation'],
        tab: 'PHOTO_INIT',
      },
      {
        index: 3,
        name: intl.formatMessage({ id: 'request.shipping' }),
        text_scheduling: intl.formatMessage({ id: 'request.request_ship' }),
        text_checkBox: intl.formatMessage({ id: 'request.new_shipping_requests' }),
        quantity: countRequest?.data?.ship,
        role: ['administrator', 'operation'],
        tab: 'SHIP',
      },
      {
        index: 4,
        name: intl.formatMessage({ id: 'request.additional_photo_shoot' }),
        text_scheduling: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
        quantity: countRequest?.data?.photoRequest,
        role: ['administrator', 'operation'],
        tab: 'PHOTO_REQUEST',
      },
      {
        index: 5,
        name: intl.formatMessage({ id: 'request.image_video_upload' }),
        text_scheduling: intl.formatMessage({ id: 'request.arrival_date' }),
        // text_checkBox: '完了済を表示',
        quantity: countRequest?.data?.photoInit,
        role: ['staff'],
        tab: 'PHOTO_INIT',
      },
      {
        index: 6,
        name: intl.formatMessage({ id: 'request.upload_additional_photos' }),
        text_scheduling: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
        quantity: countRequest?.data?.photoRequest,
        role: ['staff'],
        tab: 'PHOTO_REQUEST',
      },
      {
        index: 7,
        name: intl.formatMessage({ id: 'request.discard' }),
        text_scheduling: intl.formatMessage({ id: 'request.disposal_request_date' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_resolved' }),
        quantity: countRequest?.data?.disposal,
        role: ['staff'],
        tab: 'DISPOSAL',
      },
      {
        index: 7,
        name: intl.formatMessage({ id: 'request.discard' }),
        text_scheduling: intl.formatMessage({ id: 'request.disposal_request_date' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_completed' }),
        quantity: countRequest?.data?.disposal,
        role: ['administrator', 'operation'],
        tab: 'DISPOSAL',
      },
      {
        index: 8,
        name: intl.formatMessage({ id: 'request.inquiry' }),
        text_scheduling: intl.formatMessage({ id: 'request.mesage_sent_date' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_completed_responses' }),
        quantity: countRequest?.data?.question,
        role: ['administrator', 'staff'],
        tab: 'QUESTION',
      },
      {
        index: 9,
        name: intl.formatMessage({ id: 'request.internal_message' }),
        text_scheduling: intl.formatMessage({ id: 'request.mesage_sent_date' }),
        text_checkBox: intl.formatMessage({ id: 'request.show_completed_responses' }),
        quantity: countRequest?.data?.chat,
        role: ['administrator', 'operation', 'staff'],
        tab: 'CHAT',
      },
    ],
    [countRequest?.data],
  );

  const filteredTabTitles = useMemo(
    () => tabTitles?.filter((tab) => tab.role.some((r) => r === role)),
    [role, tabTitles],
  );
  const [tabAction, setTabAction] = useState<ITabAction>({
    activeTab: 0,
    checkboxValue: false,
    fullTextSearch: undefined,
    formattedStartDate: undefined,
    formattedEndDate: undefined,
    tab: searchParams.get('tab') ? searchParams.get('tab') : (tabTitles[0].tab as any),
  });
  const {
    data: storageItem,
    refetch: refetchStorageItem,
    isFetching: isFetchingStorageItem,
  } = useQuery({
    queryKey: [
      QUERY_KEY.STORAGE_ITEM,
      tabAction.activeTab,
      tabAction.checkboxValue,
      tabAction.fullTextSearch,
      tabAction.formattedStartDate,
      tabAction.formattedEndDate,
      tabAction.tab,
      pageSize,
      currentPage,
    ],
    enabled: enableApi,
    queryFn: async () => {
      if (role === 'administrator') {
        return await storageItemApi.storageItemControllerStorageManagementAdmin(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          undefined,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          searchParams.get('tab') ? searchParams.get('tab') : (tabAction.tab as any),
          tabAction.checkboxValue,
        );
      } else if (role === 'staff') {
        return await storageItemApi.storageItemControllerStorageManagementStaff(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          undefined,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          searchParams.get('tab') ? searchParams.get('tab') : ('PHOTO_INIT' as any),
          tabAction.checkboxValue,
        );
      } else if (role === 'operation') {
        return await storageItemApi.storageItemControllerStorageManagementOperation(
          currentPage,
          pageSize,
          undefined,
          tabAction.fullTextSearch,
          undefined,
          tabAction.formattedStartDate,
          tabAction.formattedEndDate,
          undefined,
          searchParams.get('tab') ? searchParams.get('tab') : (tabAction.tab as any),
          tabAction.checkboxValue,
        );
      }
      return null;
    },
    staleTime: 1000,
  });

  const handleTabChange = (index) => {
    if (filteredTabTitles[tabAction.activeTab]?.index === index) return;
    setLstData([]);
    setCurrentPage(1);
    setFakeSearchValue(undefined);
    refechCount();
    setTabAction({
      fullTextSearch: undefined,
      formattedStartDate: undefined,
      formattedEndDate: undefined,
      tab: searchParams.get('tab')
        ? searchParams.get('tab')
        : (filteredTabTitles.find((t) => t.index == index)?.tab as any),
      activeTab: filteredTabTitles.findIndex((t) => t.index == index),
      checkboxValue: false,
    });
    setSearchParams({ ['tab']: filteredTabTitles.find((t) => t.index == index)?.tab });
  };

  useEffect(() => {
    setLstData(storageItem?.data?.content ?? []);
  }, [storageItem?.data?.content]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleShowSizeChange = (current, size) => {
    setCurrentPage(current);
    setPageSize(size);
  };

  const handleCopy = (text, textSuccess, textFailure) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationSuccess({ content: intl.formatMessage({ id: textSuccess }) });
      })
      .catch((err) => {
        NotificationError({ content: intl.formatMessage({ id: textFailure }) });
      });
  };
  const resetState = () => {
    setInternalSkuInput('');
    setIsCheckSku({
      empty: true,
      isMatch: false,
    });
    form.resetFields();
  };

  useEffect(() => {
    if (authUser && authUser.id) {
      setEnableApi(true);
    }
  }, [authUser]);

  useEffect(() => {
    if (searchParams && searchParams.get('tab')) {
      setTabAction({
        ...tabAction,
        tab: searchParams.get('tab') ? searchParams.get('tab') : (filteredTabTitles[0].tab as any),
        activeTab: filteredTabTitles.findIndex((item) => item.tab == searchParams.get('tab')) || 0,
      });
    } else {
      const find = filteredTabTitles.findIndex((item) => item.tab == searchParams.get('tab'));
      setTabAction({
        ...tabAction,
        tab: tabTitles[0].tab as any,
        activeTab: find > 0 ? find : 0,
      });
    }
  }, [searchParams, filteredTabTitles, tabTitles]);
  const columns = [
    [3].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: '',
      key: 'action',
      className: 'col_9',
      width: 60,
      render: (item) =>
        renderStatus(item) === STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING ? (
          <Button
            onClick={() => {
              setIsOpen(true);
              setDetailItem({
                sku: item.itemSku,
                internalSku: item.internalSku,
                productName: decodeHtml(item.itemName || ''),
                shipToBuyerItemId: item.shipToBuyerItem.id,
                labelUrl: item.shipToBuyerItem.shipToBuyerRequest.shippoShippingLabel.labelUrl ?? undefined,
                battery: item.shipToBuyerItem.shipToBuyerRequest.battery,
              });
              resetState();
            }}
            className="request-management__popup-shippng"
          >
            <SvgBoxDrop />
          </Button>
        ) : null,
    },
    {
      title: '',
      key: 'detail',
      className: 'col_10',
      width: 100,
      render: (item) => (
        <ButtonDetail
          content={intl.formatMessage({ id: 'item.detail' }) + ' '}
          buttonProps={{
            onClick: (e) => {
              if (e.ctrlKey || e.metaKey) {
                window.open(`${ADMIN_ROUTE_PATH.ITEM_MANAGEMENT_DETAIL}/${item?.id}`, '_blank');
              } else {
                navigate(`${ADMIN_ROUTE_PATH.ITEM_MANAGEMENT_DETAIL}/${item?.id}`);
              }
            },
          }}
        />
      ),
    },
    [8, 9].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: '',
      key: 'notification',
      className: 'col_11',
      width: 32,
      render: (item) => (
        <div className="width-24 height-22">
          {filteredTabTitles[tabAction.activeTab]?.index === 9 &&
            item?.itemMessageGroup?.itemInternalMessages &&
            !item?.itemMessageGroup?.itemInternalMessages?.internalMessageReadHistories?.id && <SvgBlueCircle />}
          {filteredTabTitles[tabAction.activeTab]?.index === 8 &&
            item?.itemMessageGroup?.itemInquiryMessages &&
            item?.itemMessageGroup?.itemInquiryMessages?.sendUserId &&
            item?.itemMessageGroup?.itemInquiryMessages?.isRead === 0 && <SvgBlueCircle />}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'request.SKU' }),
      dataIndex: 'internalSku',
      key: 'sku',
      className: 'col_1',
      width: 180,
      render: (text, item) => (
        <div className="request-management__col-copy">
          <Button
            className="request-management__btn-copy"
            onClick={() =>
              handleCopy(
                item.internalSku,
                intl.formatMessage({ id: 'common.copy' }),
                intl.formatMessage({ id: 'common.dont_copy' }),
              )
            }
          >
            <SvgCoppy />
          </Button>
          <p>{item.internalSku}</p>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'request.customer' }),
      dataIndex: 'customer',
      key: 'customer',
      className: 'col_2',
      width: filteredTabTitles[tabAction.activeTab]?.index != 3 ? 200 : 140,
      onCell: (_, index) => {
        if ([0, 1, 5].includes(filteredTabTitles[tabAction.activeTab]?.index)) {
          if (_.isFirstItem) {
            return { rowSpan: _.count };
          } else {
            return { rowSpan: 0 };
          }
        }
        // return {};
      },
      render: (_, item) =>
        [0, 1, 5].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
          <div className={`${item.count > 1 ? 'diff' : ''}`}>
            <p className="text-1-line">{item.isFirstItem && item.storageRequest?.user?.name}</p>
            <span>{item.isFirstItem && item.storageRequest?.user?.userCode}</span>
          </div>
        ) : (
          <div>
            <p className="text-1-line">{item.storageRequest?.user?.name}</p>
            <span>{item.storageRequest?.user?.userCode}</span>
          </div>
        ),
    },
    {
      title: intl.formatMessage({ id: 'request.product_name' }),
      dataIndex: 'itemName',
      key: 'productName',
      className: 'col_3',
      width: filteredTabTitles[tabAction.activeTab]?.index != 3 ? 430 : 280,
      render: (text, item) => <p className="text-2-line break-word">{decodeHtml(item.itemName || '')}</p>,
    },
    {
      title: intl.formatMessage({ id: 'request.situation' }),
      dataIndex: 'situation',
      key: 'situation',
      className: 'col_4',
      width: filteredTabTitles[tabAction.activeTab]?.index != 3 ? 228 : 120,
      render: (text, item) => {
        const inquiryStatus: { [key in (typeof statusChat)[number]]: string } = {
          OPEN: intl.formatMessage({ id: 'chat-item.status.open' }),
          CLOSED: intl.formatMessage({ id: 'chat-item.status.closed' }),
          WAITING_CUSTOMER: intl.formatMessage({ id: 'chat-item.status.inProgress' }),
        };
        if ([0, 1, 2, 3, 5, 7].includes(filteredTabTitles[tabAction.activeTab]?.index)) {
          return (
            <p>
              {intl.formatMessage({ id: `request.${renderStatus(item)}` })}
              {item.status === STATUS_ITEM_MANAGEMENT.IN_STORAGE && item.storageItemRacks.length > 0
                ? `_${item.storageItemRacks[0]?.rack?.code}`
                : ''}
            </p>
          );
        } else if (filteredTabTitles[tabAction.activeTab]?.index === 4) {
          return <p>{intl.formatMessage({ id: `request.${renderStatusImage(item.takePhotoRequest?.status)}` })}</p>;
        } else if (filteredTabTitles[tabAction.activeTab]?.index === 8) {
          return (
            <p>
              {/* {handleShowInquiryDiscussStatus({
                status: item?.inquiryStatus,
                isRead: item?.itemMessageGroup?.itemInquiryMessages?.isRead,
                intlMultiLang: intl,
              })} */}
              {inquiryStatus[item?.inquiryStatus]}
            </p>
          );
        } else if (filteredTabTitles[tabAction.activeTab]?.index === 9) {
          return (
            <p>
              {handleShowInternalDiscussStatus({
                status: item?.internalDiscussStatus,
                isRead: item?.itemMessageGroup?.itemInternalMessages?.internalMessageReadHistories?.id ? 1 : 0,
                authUserId: authUser?.id,
                sendBy: item?.itemMessageGroup?.itemInternalMessages?.sendStaffId,
                intlMultiLang: intl,
              })}
            </p>
          );
        } else if (filteredTabTitles[tabAction.activeTab]?.index === 6) {
          return <p>{intl.formatMessage({ id: `request.${renderStatusImage(item.takePhotoRequest?.status)}` })}</p>;
        }
      },
    },
    [3].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.rackno' }),
      key: 'rack',
      className: 'col_12',
      width: 136,
      render: (item) => <p>{item.storageItemRacks.length > 0 && item.storageItemRacks[0]?.rack?.code}</p>,
    },
    [3].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.carrier' }),
      key: 'carrier',
      className: 'col_13',
      width: 100,
      render: (item) => <p>{item?.shipToBuyerItem?.shipToBuyerRequest?.shippingCarrier}</p>,
    },
    [9].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.manager' }),
      key: 'manager',
      className: 'col_5',
      width: 160,
      render: (item) => (
        <Avatar
          src={
            item?.itemMessageGroup?.assign?.avatar?.preview ? (
              Helper.getSourceFile(item?.itemMessageGroup?.assign?.avatar?.preview)
            ) : (
              <SvgAvatarDefault />
            )
          }
        />
      ),
    },
    [9].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.date_send_new' }),
      key: 'dateSendNew',
      className: 'col_6',
      width: 168,
      render: (item) => <p>{formatDate(item?.itemMessageGroup?.itemInternalMessages?.createdAt)}</p>,
    },
    [0, 1, 2, 5].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.arrival_date' }),
      key: 'arrivalDate',
      className: 'col_7_1',
      width: 160,
      onCell: (_, index) => {
        if ([0, 1, 5].includes(filteredTabTitles[tabAction.activeTab]?.index)) {
          if (_.isFirstItem) {
            return { rowSpan: _.count };
          } else {
            return { rowSpan: 0 };
          }
        }
        // return {};
      },
      render: (item) =>
        [0, 1, 5].includes(filteredTabTitles[tabAction.activeTab]?.index) ? (
          <div className={`${item.count > 1 ? 'diff' : ''}`}>
            <p>{formatDate(item.isFirstItem && item.storageRequest?.deliveredAt)}</p>
          </div>
        ) : (
          <p>{formatDate(item.storageRequest?.deliveredAt)}</p>
        ),
    },

    [4, 6].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.date_photo_shot_rq' }),
      key: 'datePhotoShot',
      className: 'col_7_2',
      width: 160,
      render: (item) => <p>{formatDate(item.takePhotoRequest?.createdAt)}</p>,
    },

    [3].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.request_ship' }),
      key: 'requestShipDate',
      className: 'col_7_3',
      width: filteredTabTitles[tabAction.activeTab]?.index != 3 ? 160 : 112,
      render: (item) => <p>{formatDate(item.shipToBuyerItem?.shipToBuyerRequest?.createdAt)}</p>,
    },

    [3].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.deadline' }),
      key: 'deadline',
      className: 'col_3',
      width: 112,
      render: (text, item) => {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return (
          <p className="text-2-line break-word">
            {authUser?.role !== 'operation'
              ? item?.shipToBuyerItem?.shipToBuyerRequest?.shipByDate
                ? moment(item?.shipToBuyerItem?.shipToBuyerRequest?.shipByDate)
                    .tz(currentTimezone)
                    .format(`YYYY/MM/DD HH:mm z`)
                : ''
              : item?.shipToBuyerItem?.shipToBuyerRequest?.shipByDate
                ? moment(item.shipToBuyerItem.shipToBuyerRequest.shipByDate)
                    .tz(TokyoTimezone)
                    .subtract(24, 'hours')
                    .format(TIME_FORMAT.DATE('/'))
                : ''}
          </p>
        );
      },
    },
    [7].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.disposal_request_date' }),
      key: 'disposalRequestDate',
      className: 'col_7_4',
      width: 160,
      render: (item) => <p>{formatDate(item.disposalRequest?.createdAt)}</p>,
    },

    [8].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.date_send_new' }),
      key: 'dateSendNew',
      className: 'col_7_5',
      width: 160,
      render: (item) => <p>{formatDate(item.itemMessageGroup?.itemInquiryMessages?.createdAt)}</p>,
    },
    [0, 1, 2, 3, 4, 5, 6, 7].includes(filteredTabTitles[tabAction.activeTab]?.index) && {
      title: intl.formatMessage({ id: 'request.remarks' }),
      key: 'remarks',
      className: 'col_8',
      width: filteredTabTitles[tabAction.activeTab]?.index != 3 ? 426 : 280,
      render: (item) => <p className="text-2-line break-word text-pre-line">{item?.storageItemNote?.content}</p>,
    },
  ].filter(Boolean);

  const resultLstData = lstData.flatMap((item, index) => {
    return item?.storageItems.map((storageItem, storageIndex) => ({
      ...storageItem,
      isFirstItem: storageIndex === 0,
      count: item?.storageItems?.length,
      parentId: index ? index : null,
    }));
  });
  const renderTableContent = () => {
    return (
      <div className="request-management_table">
        <div className="request-management_table_1_wrap">
          <TableWrap
            className=""
            columns={columns}
            data={resultLstData}
            isScroll
            scrollValue={{ y: '65vh' }}
            rowClassName={(_, index) => (_.parentId % 2 === 0 ? 'even-row' : 'odd-row')}
            rowHoverable={false}
            isLoading={isFetchingCount || isFetchingStorageItem}
          />
        </div>
        <div className="request-management__table-pagination-container">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={storageItem?.data?.total}
            showSizeChanger
            pageSizeOptions={[20, 50, 100]}
            locale={{ items_per_page: intl.formatMessage({ id: 'item.lines' }) }}
            onChange={handlePageChange}
            onShowSizeChange={handleShowSizeChange}
          />
        </div>
      </div>
    );
  };
  const handleCheckboxChange = (e) => {
    setLstData([]);
    setTabAction({
      ...tabAction,
      checkboxValue: e,
    });
  };
  const onFinish = (values: UpdatePreparingDoneDto) => {
    if (values) {
      processPreparingDone.mutate({ shipToBuyerItemId: detailItem.shipToBuyerItemId, internalSku: values.internalSku });
    }
  };

  const onFinishFailed = (values) => {
    NotificationError({
      content: intl.formatMessage({ id: 'request.bar_code_error' }),
    });
  };

  const processPreparingDone = useMutation({
    mutationFn: (payload: UpdatePreparingDoneDto) =>
      storageProcessApi.storageProcessControllerSetShipToBuyPreparingDone(payload),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      refetchStorageItem();
      setIsCheckSku({
        empty: true,
        isMatch: false,
      });
      setIsOpen(false);
    },
    onError: (error: any) => {
      // NotificationError({ content: 'Bad request' });
      // CustomHandleError(error?.response?.data, intl);
    },
  });

  const checkProcessPreparingDone = useMutation({
    mutationFn: (payload: UpdatePreparingDoneDto) =>
      storageProcessApi.storageProcessControllerCheckSetPreparingDone(payload),
    onSuccess: (res: any) => {
      // NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      if (res.data.result) {
        setIsCheckSku({
          empty: false,
          isMatch: true,
        });
      } else {
        setIsCheckSku({
          empty: false,
          isMatch: false,
        });
      }
    },
    onError: (error: any) => {},
  });
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkProcessPreparingDone.mutate({
        shipToBuyerItemId: detailItem.shipToBuyerItemId,
        internalSku: internalSkuInput,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [isOpen]);

  // const handleKeyDownSearch = (event) => {
  //   if (event.key === 'Enter') {
  //     setTimeout(() => {
  //       setTabAction({ ...tabAction, fullTextSearch: event.target.value || undefined });
  //     }, 500);
  //   }
  // };

  const handleSearch = (value: string) => {
    if (refDebounceSearch.current) clearTimeout(refDebounceSearch.current);
    refDebounceSearch.current = setTimeout(() => {
      setCurrentPage(1);
      setTabAction({ ...tabAction, fullTextSearch: value?.trim() });
    }, 500);
  };

  const disabledDateEnd = (current) => {
    if (!!tabAction.formattedStartDate) {
      return current && current < dayjs(tabAction.formattedStartDate).startOf('day');
    } else {
      return undefined;
    }
  };
  const disabledDateStart = (current) => {
    if (!!tabAction.formattedEndDate) {
      return current && current > dayjs(tabAction.formattedEndDate).startOf('day');
    } else {
      return undefined;
    }
  };
  return (
    <div className="request-management">
      <div className="request-management_container">
        <h2 className="request-management_title">{intl.formatMessage({ id: 'request.title' })}</h2>
        <div className="request-management_tab-title">
          {filteredTabTitles.map((tab) => {
            return (
              <div
                key={tab?.index}
                className={`request-management_tab-title_item width_${role} ${
                  filteredTabTitles[tabAction.activeTab]?.index === tab?.index ? 'active' : ''
                }`}
                onClick={() => handleTabChange(tab?.index)}
              >
                <p className="request-management_tab-title_item_name request-management_tab-title_item-hover">
                  {tab.name}
                </p>
                <span className="request-management_tab-title_item_quantity">{tab.quantity}</span>
              </div>
            );
          })}
        </div>
        <div className="request-management_filter">
          <div className="request-management_filter_date-wrap_date">
            <p className="request-management_filter_date-wrap_date_text m-r-4">
              {filteredTabTitles[tabAction.activeTab]?.text_scheduling}
            </p>
            <DatepickerCustom
              datePickerProp={{
                onChange: (_, dateString: string) => {
                  setTabAction({
                    ...tabAction,
                    formattedStartDate: dateString,
                  });
                },
                disabledDate: disabledDateStart,
                value: tabAction.formattedStartDate ? dayjs(tabAction.formattedStartDate) : undefined,
              }}
            />
            <span>~</span>
            <DatepickerCustom
              datePickerProp={{
                onChange: (_, dateString: string) => {
                  setTabAction({
                    ...tabAction,
                    formattedEndDate: dateString,
                  });
                },
                disabledDate: disabledDateEnd,
                value: tabAction.formattedEndDate ? dayjs(tabAction.formattedEndDate) : undefined,
              }}
            />
          </div>
          {filteredTabTitles[tabAction.activeTab]?.text_checkBox && (
            <div className="request-management_filter_checkbox">
              {/* <div className="checkbox-custom">
                <CustomCheckbox checked={tabAction.checkboxValue} onChange={handleCheckboxChange}>
                  <span className={`checkbox-custom__checkbox-title`}>{filteredTabTitles[tabAction.activeTab].text_checkBox}</span>
                </CustomCheckbox>
              </div> */}
              <CheckboxCustom
                checked={tabAction.checkboxValue}
                onChange={handleCheckboxChange}
                content={filteredTabTitles[tabAction.activeTab].text_checkBox}
              />
            </div>
          )}
          <div>
            <CustomSearchInput
              className="font-weight-500 font-size-16 switch-false request-management__search-input"
              placeholder={intl.formatMessage({ id: 'item.searchPlaceholder' })}
              // onKeyDown={handleKeyDownSearch}
              value={fakeSearchValue}
              onChange={({ target: { value } }) => {
                setFakeSearchValue(value);
                handleSearch(value);
              }}
            />
          </div>
        </div>
        <div className="request-management_table">{renderTableContent()}</div>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'requestShipping.title' })}
        closable={true}
        open={isOpen}
        centered={true}
        onCancel={() => setIsOpen(false)}
        className={'request-management__modal-shipping'}
        closeIcon={<SvgCloseModalIcon />}
        footer={
          <div className="request-management__btn-container">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'request-management__btn-print',
                onClick: () => {
                  window.open(detailItem.labelUrl, '_blank');
                },
                disabled: !isCheckSku.isMatch || processPreparingDone.isPending || !detailItem.labelUrl,
              }}
              content={
                <p>
                  <SvgPrintIcon />
                  {intl.formatMessage({ id: 'common.print' })}
                </p>
              }
            />
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                disabled: !isCheckSku.isMatch || processPreparingDone.isPending || !checkPin,
                loading: processPreparingDone.isPending,
                className: 'request-management__btn-cancel',
                type: 'primary',
                onClick: () => form.submit(),
              }}
              content={intl.formatMessage({ id: 'common.ready' })}
            />
          </div>
        }
      >
        <div className="request-management__product-info">
          <p>{intl.formatMessage({ id: 'requestShipping.productInfo' })}</p>
        </div>
        <div className="request-management__gird-container">
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>SKU</span>
            </div>
            <div className="request-management__col-right-container">
              <span>{detailItem.internalSku}</span>
            </div>
          </div>
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>{intl.formatMessage({ id: 'requestShipping.customerSKU' })}</span>
            </div>
            <div className="request-management__col-right-container">
              <span>{detailItem.sku}</span>
            </div>
          </div>
          <div className="request-management__row-container">
            <div className="request-management__col-left-container">
              <span>{intl.formatMessage({ id: 'requestShipping.productName' })}</span>
            </div>
            <div className="request-management__col-right-container">
              <p className="text-1-line">{detailItem.productName}</p>
            </div>
          </div>
        </div>
        <FormWrap
          form={form}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="request-management__form-request-shiping"
        >
          <Form.Item
            name={n('internalSku')}
            className="request-management__product-bar-code m-b-12"
            label={
              <p className="font-size-16">
                {intl.formatMessage({ id: 'requestShipping.productBarcode' })}
                <span className="request-management__require">*</span>
              </p>
            }
            required
            rules={[{ required: true, message: '' }]}
          >
            <Input
              suffix={<SvgIncrease />}
              autoFocus={true}
              className="font-size-16"
              ref={inputRef}
              placeholder={intl.formatMessage({ id: 'request.bar_code_placeholder' })}
              onChange={(e) => {
                const value = e.target.value ?? '';
                setInternalSkuInput(value);
                if (value == '') {
                  resetState();
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item className="m-b-12">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'request-management__btn-confirm font-weight-600',
                onClick: () => {
                  if (internalSkuInput.trim().length > 0) {
                    checkProcessPreparingDone.mutate({
                      shipToBuyerItemId: detailItem.shipToBuyerItemId,
                      internalSku: internalSkuInput.trim(),
                    });
                  } else {
                    form.validateFields();
                  }
                },
              }}
              content={intl.formatMessage({ id: 'request.ship_ready.check' })}
            />
          </Form.Item>
        </FormWrap>
        <div className="request-management__judgement-container">
          <div className="request-management__judgement-title">
            {isCheckSku.empty ? (
              <></>
            ) : (
              <p>
                {intl.formatMessage({ id: 'requestShipping.judgement' })}
                <span>&nbsp;:</span>
              </p>
            )}
          </div>
          <div className="request-management__judgement-description">
            {isCheckSku.empty ? (
              <></>
            ) : (
              <p className="request-management__status-sku">
                {isCheckSku.isMatch ? (
                  <>
                    <span>{intl.formatMessage({ id: 'requestShipping.match' })}</span>
                    <SvgMatch />
                  </>
                ) : (
                  <>
                    {' '}
                    <span>{intl.formatMessage({ id: 'requestShipping.notMatch' })}</span>
                    <SvgNotMatch />
                  </>
                )}
              </p>
            )}
          </div>
        </div>
        {detailItem.battery && (
          <div className="request-management__pin">
            <CustomCheckbox
              name="pin"
              checked={checkPin}
              onChange={({ target: { checked } }) => setCheckPin(checked)}
            />
            <p>
              以下のUNラベルを貼付しました
              <br />
              {detailItem.battery.match(/UN\d+/g)}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RequestManagement;
